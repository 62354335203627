import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
// action types
export const UPDATE_TABLE_STRUCTURE = "getPRODUCTSTableStructure";
export const UPDATE_TABLE_DATA = "getPRODUCTSTableData";
export const UPDATE_CREATE_DATA = "getPRODUCTSCreateData";
export const EXPORT_TABLE_DATA = "exportPRODUCTSTableData";

// mutation types
export const SET_TABLE_STRUCTURE = "setPRODUCTSTableStructure";
export const SET_TABLE_DATA = "setPRODUCTSTableData";
export const SET_TABLE_FILTER = "setPRODUCTSTableFilter";
export const SET_TABLE_PAGE = "setPRODUCTSTablePage";
export const SET_TABLE_PERPAGE = "setPRODUCTSTablePerPage";
export const SET_TABLE_SORTBY = "setPRODUCTSTableSortBy";
export const SET_TABLE_SORTORDER = "setPRODUCTSTableSortOrder";
export const SET_CREATE_DATA = "setPRODUCTSCreateData";
export const SET_EXPORT_LINK = "setPRODUCTSExportLink";

const state = {
  export_link: null,
  table_structure: {},
  table_data: {},
  create_data: {},
  table_state: {
    page: 1,
    per_page: 50,
    sortBy: "id",
    sortOrder: "desc",
    filters: [],
  },
};

const getters = {
  getPRODUCTSTableState(state) {
    return state.table_state;
  },

  getPRODUCTSTableHeaders(state) {
    return state.table_structure.columns;
  },

  getPRODUCTSTableFilters(state) {
    // console.log(state.table_structure.filters);
    return state.table_structure.filters;
  },

  getPRODUCTSTablePermissions(state) {
    return state.table_structure.permissions;
  },

  getPRODUCTSTableData(state) {
    return state.table_data.data;
  },

  getPRODUCTSBrandsData(state) {
    return state.create_data.brands;
  },

  getPRODUCTSStoresData(state) {
    return state.create_data.stores;
  },

  getPRODUCTSParametersData(state) {
    return state.create_data.parameters;
  },

  getPRODUCTSTableProperties(state) {
    return state.table_structure.properties;
  },
  getPRODUCTSExportUrl(state) {
    return state.export_link;
  },
};

const actions = {
  [UPDATE_TABLE_STRUCTURE](context) {
    return new Promise((resolve) => {
      ApiService.post("/stores/portal/products")
        .then(({ data }) => {
          context.commit(SET_TABLE_STRUCTURE, data);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [UPDATE_CREATE_DATA](context) {
    return new Promise((resolve) => {
      ApiService.post("/stores/portal/products/create")
        .then(({ data }) => {
          context.commit(SET_CREATE_DATA, data);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [UPDATE_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/stores/portal/products/datatable", payload)
        .then(({ data }) => {
          context.commit(SET_TABLE_DATA, data);
          resolve(data);

          context.commit(SET_PAGE_LOADING, false);
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [EXPORT_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/stores/portal/products/export", payload)
        .then(({ data }) => {
          context.commit(SET_EXPORT_LINK, data.url);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
};

const mutations = {
  [SET_TABLE_FILTER](state, filter) {
    state.table_state.filters = filter;
  },
  [SET_TABLE_PAGE](state, page) {
    state.table_state.page = page;
  },
  [SET_TABLE_PERPAGE](state, perPage) {
    state.table_state.per_page = perPage;
  },
  [SET_TABLE_SORTBY](state, sortBy) {
    state.table_state.sortBy = sortBy;
  },
  [SET_TABLE_SORTORDER](state, sortOrder) {
    state.table_state.sortOrder = sortOrder;
  },
  [SET_TABLE_STRUCTURE](state, table_structure) {
    state.table_structure = table_structure.data;
  },
  [SET_TABLE_DATA](state, table_data) {
    state.table_data = table_data;
  },
  [SET_CREATE_DATA](state, create_data) {
    state.create_data = create_data;
  },
  [SET_EXPORT_LINK](state, link) {
    state.export_link = link;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
