import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
// import JwtService from "@/core/services/jwt.service";
// import axios from "axios";

// action types
export const UPDATE_DASHBOARD_DATA = "getDELIVERYTableData";

// mutation types
export const SET_DASHBOARD_DATA = "setDELIVERYTableData";
export const SET_FILTER_DATA = "setDELIVERYFilter";
export const SET_FILTERS_FOR_ORDERS_PAGE = "setDELIVERYFiltersForOrdersPage";
export const SET_FILTER_STATUSES = "setDELIVERYFilterStatuses";

const state = {
  dashboard_data: [],
  filters: {},
  filters_for_orders_page: {},
  filterStatuses: {},
};

const getters = {
  getDELIVERYTableData(state) {
    return state.dashboard_data;
  },
  getDELIVERYFilter(state) {
    return state.filters;
  },
  getDELIVERYFilterStatuses: function (state) {
    return state.filterStatuses;
  },
};

const actions = {
  [UPDATE_DASHBOARD_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      // ApiService.post("login", credentials)
      ApiService.post("/dashboards/dashboardDelivery", payload)
        .then(({ data }) => {
          // console.log("data of datatable ", data.charts);
          context.commit(SET_DASHBOARD_DATA, data?.charts);
          context.commit(SET_FILTER_DATA, data?.filters);
          context.commit(SET_FILTER_STATUSES, data.orderStatuses);
          resolve(data);

          context.commit(SET_PAGE_LOADING, false);
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
};

const mutations = {
  [SET_DASHBOARD_DATA](state, data) {
    state.dashboard_data = data;
  },
  [SET_FILTER_DATA](state, data) {
    state.filters = data;
  },
  // [SET_FILTERS_FOR_ORDERS_PAGE](state, data) {

  // }
  [SET_FILTER_STATUSES](state, statuses) {
    state.filterStatuses = statuses;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
