// state for Filter component

// mutation types
export const SET_FILTERS_DATA = "setFiltersData";

const state = {
  filters_data: {},
};

const getters = {
  getFiltersData(state) {
    // console.log(state.filters_data);
    return state.filters_data;
  },
};

const mutations = {
  [SET_FILTERS_DATA](state, filters_data) {
    // console.log("mutating filter", filters_data);
    state.filters_data = filters_data;
  },
};

export default {
  state,
  mutations,
  getters,
};
