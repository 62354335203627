import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
// import JwtService from "@/core/services/jwt.service";
// import axios from "axios";

// action types
export const UPDATE_DASHBOARD_DATA = "getANALYTICSTableData";

// mutation types
export const SET_DASHBOARD_DATA = "setANALYTICSTableData";
export const SET_FILTER_DATA = "setANALYTICSFilter";

const state = {
  dashboard_data: [],
  filters: [],
};

const getters = {
  getANALYTICSTableData(state) {
    return state.dashboard_data;
  },
  getANALYTICSFilter(state) {
    return state.filters;
  },
};

const actions = {
  [UPDATE_DASHBOARD_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      // ApiService.post("login", credentials)
      ApiService.post("/dashboards/dashboardAnalytics", payload)
        .then(({ data }) => {
          // console.log("data of datatable ", data.charts);
          context.commit(SET_DASHBOARD_DATA, data?.charts);
          context.commit(SET_FILTER_DATA, data?.filters);
          resolve(data);

          context.commit(SET_PAGE_LOADING, false);
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
};

const mutations = {
  [SET_DASHBOARD_DATA](state, data) {
    state.dashboard_data = data;
  },
  [SET_FILTER_DATA](state, data) {
    state.filters = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
